import React, { useEffect, useState } from 'react'
import EventRecent from '../components/Updates/EventRecent'
import { useParams } from 'react-router-dom'
import { eventDetails } from '../actions';
import { useTranslation } from 'react-i18next';
import Slider from "react-slick";
import useTranslate from '../components/useTranslate';
import moment from 'moment';
import { API_IMAGE_URL } from '../configuration';
import MetaHead from '../components/MetaHead/MetaHead';

export default function EventDetails() {
  const { i18n } = useTranslation();
  const { t } = useTranslate();
  const { id } = useParams();
  const [details, setDetails] = useState({})
  // LANGUAGE
  const [lang, setLang] = useState("");
  const langSwitch = i18n?.language === "en";
  useEffect(() => {
    i18n.on("languageChanged", (language) => {
      setLang(language);
    });
  }, [lang, i18n]);

  useEffect(() => {
    eventDetails(id, (res) => {
      setDetails(res.data)
    })
  }, [id])



  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false
  };
  return (
    console.log(details?.gallery, 'akesh'),
    <>
      <MetaHead
        title={details?.title}
        desc={details.content}
      // keyword={details?.meta_keyword}
      />
      <section className='detail__page'>
        <div className="container">
          <div className="title">
            <h6>{t('Events')}</h6>
            <h1>{langSwitch ? details?.title : details?.title_ar}</h1>
            <h5><span>{moment(details?.event_date).format('MMMM DD, yyyy')}</span> | <span>{moment(details?.event_date).format('hh : mm A')}</span> | <span>{langSwitch ? details?.location : details?.location_ar}</span></h5>
          </div>
          <div className="details">
            <div className="slider">
              <Slider {...settings}>
                <img src={API_IMAGE_URL + details?.file_path + details?.image} className='w-100' alt="" />
                {
                  details?.gallery
                    ?.filter(ele => ele)
                    .map((ele, i) => {
                      return (
                        <img
                          src={API_IMAGE_URL + details?.file_path + ele}
                          className="w-100"
                          alt=""
                          key={i} 
                        />
                      );
                    })
                }
              </Slider>
            </div>
            <p className='m-0' dangerouslySetInnerHTML={{ __html: langSwitch ? details.content : details.content_ar }}></p>
          </div>
          <EventRecent id={id} />
        </div>
      </section>
    </>

  )
}
