import React, { useEffect, useState } from 'react'
import { OAuthProvider, FacebookAuthProvider, GoogleAuthProvider, TwitterAuthProvider, signInWithPopup, AppleAuthProvider } from 'firebase/auth';
import { auth } from '../../firebase';
import './ModalStyles.scss'
import { userLogin } from '../../actions';
import useTranslate from '../useTranslate';
import { toast } from 'react-toastify';
export default function Signup({ setSignupshow }) {
  const token = localStorage.getItem('token')
  const csrfToken = localStorage.getItem('csrfToken')
  const { t } = useTranslate();

  const signInWithGooogle = async () => {
    try {
      const provider = new GoogleAuthProvider();
      provider.addScope('email');
      const result = await signInWithPopup(auth, provider);
      localStorage.setItem('userId', result.user?.uid)
      if (result.user) {
        const data = {
          name: result.user?.displayName,
          email: result?.user?.providerData[0]?.email,
          social_id: result.user?.uid,
          phone: result.user?.phoneNumber ? result.user?.phoneNumber : ""
        }
        userLogin(data, (res) => {
          const token = res?.data?.token?.accessToken;
          const csrfToken = res?.data?.token?.csrfToken;
          if(res.status){
            toast.success(t('login-successfully'))
          }
          
          if (token) {
            localStorage.setItem('token', token);
            localStorage.setItem('csrfToken', csrfToken);
          }
          localStorage.setItem('user', JSON.stringify(res.data))
          setSignupshow(false)
        })
      }

    } catch (error) {
      console.log(error)
    }
  }
  // APPLE
  const signInWithApple = async () => {
    try {
      const provider = new OAuthProvider('apple.com');
     
      provider.addScope('email');
      provider.addScope('name');
      const result = await signInWithPopup(auth, provider);
      console.log(result)
      localStorage.setItem('userId', result.user?.uid)
      if (result) {
        const userDetails = result.user.providerData[0]
        const data = {
          name: userDetails?.displayName,
          email: userDetails?.email,
          social_id: result.user?.uid
        }
        userLogin(data, (res) => {
          localStorage.setItem('user', JSON.stringify(res.data))
          setSignupshow(false)

        })
      }
    } catch (error) {
      console.log(error)
    }
  }
  // FACEBOOK

  const signInWithFacebook = async () => {
    try {
      const provider = new FacebookAuthProvider();

      const result = await signInWithPopup(auth, provider);
      localStorage.setItem('userId', result.user?.uid)

      if (result.user) {
        const data = {
          name: result.user?.displayName,
          email: result.user?.providerData[0]?.email,
          phone: result.user?.phoneNumber ? result.user?.phoneNumber : "",
          social_id: result.user?.uid
        }
        userLogin(data, (res) => {
          localStorage.setItem('user', JSON.stringify(res.data))
          setSignupshow(false)

        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  // TWITTER

  const signInWithX = async () => {
    try {
      const provider = new TwitterAuthProvider();
      const result = await signInWithPopup(auth, provider);
      localStorage.setItem('userId', result.user?.uid)
      if (result.user) {
        const data = {
          name: result.user?.displayName,
          email: result.user?.providerData[0]?.email,
          phone: result.user?.phoneNumber ? result.user?.phoneNumber : "",
          social_id: result.user?.uid
        }
        userLogin(data, (res) => {
          localStorage.setItem('user', JSON.stringify(res.data))
          setSignupshow(false)
        })
      }
    } catch (error) {
      console.log(error)
    }
  }



  return (
    <div className='login__account'>
      {/* <button className='btn btn-outline-primary' onClick={signInWithApple}><img src="/assets/img/icons/apple.svg" className='dark' alt="" /> {t('Sign up with apple')}</button> */}
      <button className='btn btn-outline-primary' onClick={signInWithGooogle}> <img src="/assets/img/icons/google.svg" alt="" /> {t('Sign up with Google')}</button>
      {/* <button className='btn btn-outline-primary' onClick={signInWithFacebook}> <img src="/assets/img/icons/fb-blue.svg" alt="" /> {t('Sign up with Facebook')}</button> */}
      <button className='btn btn-outline-primary' onClick={signInWithX}>{t('Sign up with')} <img src="/assets/img/icons/x.svg" className='dark' alt="" /></button>
    </div>
  )
}
