import React, { useEffect, useRef, useState } from 'react'
import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select'
import { Uploader, Button, IconButton } from 'rsuite'
import { fileUploader, getCountry, getIncidentTypes, reportIncident, uploadFileChunked } from '../../actions';
import { useTranslation } from 'react-i18next';
import useTranslate from '../useTranslate';
import { Link } from 'react-router-dom';
import { LoadScript, Autocomplete } from '@react-google-maps/api';

import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { API_BASE_URL, CRM_API_BASE_URL, FLAG_BASE_URL } from '../../configuration';
import { toast } from 'react-toastify';
// FIRBASE
import { getAuth, RecaptchaVerifier } from 'firebase/auth';
import { auth } from '../../firebase';
import { Spinner } from 'react-bootstrap';
import { format } from 'rsuite/esm/utils/dateUtils';
import { fileChunksUploder } from '../../utils/Utils';
function ReportIncident({ setShow }) {
    const { i18n } = useTranslation();
    const hasRendered = useRef(false);
    const { t } = useTranslate();
    const [user, setUser] = useState()
    const [refId, setRefeId] = useState()
    const [errors, setErrors] = useState({})
    const [countries, setCountries] = useState([])
    const [success, setSuccess] = useState(false)
    const [flagImage, setFlagImage] = useState('')
    const counter = localStorage.getItem('counter')
    const [autocomplete, setAutocomplete] = useState(null);
    const [currentLatLng, setCurrentLatLng] = useState({})
    const [incidentLatLng, setIncidentLatLng] = useState({})
    const [loading, setLoading] = useState(false)
    const [submitLoading, setSubmitLoading] = useState(false)
    const inputRef = useRef(null);
    const [recaptchaVerified, setRecaptchaVerified] = useState(false);
    const [placeName, setPlaceName] = useState('');

    const [incidentFileID, setIncidentFileId] = useState([])


    // GET EMAIL
    const randomTrackID = localStorage.getItem('trackID');
    const storedUser = localStorage.getItem('user');
    const userId = localStorage.getItem('userId') ?? "";
    const token = localStorage.getItem('token');
    const csrfToken = localStorage.getItem('csrfToken');
    const device_id = localStorage.getItem('device_id')
    const ipAddress = localStorage.getItem('ip')
    useEffect(() => {
        setUser(storedUser ? JSON.parse(storedUser) : {});


        navigator?.geolocation.getCurrentPosition(
            ({ coords: { latitude: lat, longitude: lng } }) => {
                const pos = { lat, lng };
                setCurrentLatLng(pos)

            }
        );

    }, []);

    // LANGUAGE
    const [lang, setLang] = useState("");
    const langSwitch = i18n?.language === "en";
    useEffect(() => {
        i18n.on("languageChanged", (language) => {
            setLang(language);
        });
    }, [lang, i18n]);
    const [inputs, setInputs] = useState({
        customer_email: user?.email || ''
    });
    const [incidentTypes, setIncidentTypes] = useState([])
    const [uploadedFiles, setUploadedFiles] = useState([])

    useEffect(() => {
        getIncidentTypes((res) => {
            setIncidentTypes(res.data)
        })
        getCountry((res) => {
            setCountries(res)
        })


    }, [])

    // useEffect(() => {
    //     onLoad()
    //     onPlaceChanged()
    // }, [setShow])



    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: currentLatLng }, (results, status) => {
        if (status === 'OK' && results[0]) {
            setPlaceName(results[0].formatted_address);
        } else {
            console.error('Geocoder failed due to:', status);
        }
    });


    const onLoad = (autocompleteInstance) => {
        setAutocomplete(autocompleteInstance);
    };



    const onPlaceChanged = () => {
        if (autocomplete !== null) {
            const place = autocomplete?.getPlace();
            const lat = place.geometry.location.lat();
            const lng = place.geometry.location.lng();
            setIncidentLatLng({ lat, lng });
            changeHandler('incident_location', place?.formatted_address)
        } else {
            console.log('Autocomplete is not loaded yet!');
        }
    };

    useEffect(() => {
        setInputs(prevInputs => ({
            ...prevInputs,
            customer_email: user?.email || ''
        }));
    }, [user]);

    function changeHandler(field, value) {
        setErrors(s => ({ ...s, [field]: '' }))
        setInputs(s => ({ ...s, [field]: value }))
    }

    // RANDOM TRACKID
    function generateTrackID(length) {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let trackID = '';

        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            trackID += characters.charAt(randomIndex);
        }

        localStorage.setItem('trackID', trackID);
        return trackID;
    }

    useEffect(() => {
        if (!hasRendered.current) {
            setupRecaptcha()

            hasRendered.current = true;
        }
        generateTrackID(8)
    }, []);


    const setupRecaptcha = () => {
        try {
            window.recaptchaVerifier = new RecaptchaVerifier(auth, 'IncidentCaptcha', {
                'size': 'large',
                'theme': 'light',
                'callback': (response) => {
                    setRecaptchaVerified(true);
                },
                'expired-callback': () => {
                    setRecaptchaVerified(false);
                }
            });

            window.recaptchaVerifier.render().then(() => {
            }).catch((error) => {
                console.error('Error rendering reCAPTCHA:', error);
            });
        } catch (error) {
            console.error('Error setting up reCAPTCHA:', error);
        }
    };

    // END
    const handlbeforeUpload = (newFileList) => {
        const allowedTypes = [
            // Images
            'image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/webp', 'image/tiff', 'image/svg+xml',

            // Audio
            'audio/mpeg', 'audio/mp3', 'audio/wav', 'audio/ogg', 'audio/midi', 'audio/x-midi', 'audio/aac', 'audio/flac',

            // Video
            'video/mp4', 'video/ogg', 'video/webm', 'video/quicktime', 'video/x-msvideo', 'video/x-ms-wmv', 'video/mpeg',

            // PDF
            'application/pdf'
        ];
        let totalSize = 0
        let isFileValidator = true
        newFileList?.forEach(file => {
            totalSize = totalSize + file.blobFile.size
            if (!allowedTypes?.includes(file?.blobFile?.type)) {
                isFileValidator = false;
            }
        })
        if ((totalSize / 1000000) > 200) {
            setErrors(s => ({ ...s, file_upload: 'You cannot upload more than 200 MB' }))
            return false
        } else {
            setErrors(s => ({ ...s, file_upload: '' }))
        }
        if (isFileValidator) {
            return true;
        } else {
            toast.error('Invalid file format. Only images, video, audio, and PDF files are supported.')
            return false;
        }
    }


    // const handleChange = (newFileList) => {
    //     setLoading(true)
    //     const formData = new FormData();
    //     formData.append('track_id', randomTrackID);
    //     formData.append('crmDestinationpath', 'request')

    //     let files = []

    //     newFileList.forEach(file => {
    //         files.push({
    //             ...file,
    //             id: uploadedFiles.find(f => file.name === f.name)?.id
    //         })
    //         if (!uploadedFiles.find(f => f.fileKey === file.fileKey)) {
    //             formData.append('file', file.blobFile);
    //         }

    //     });

    //     if (Object.fromEntries(formData).file) {
    //         fileUploader(formData, (res) => {
    //             setLoading(false)
    //             setUploadedFiles(files.map(file => {
    //                 console.log({ id: file.id, ne: res.data.webResponseArray[0][file.name], test: res.data.webResponseArray[0], })
    //                 return {
    //                     ...file,
    //                     id: file.id || res.data.webResponseArray[0][file.name]
    //                 }
    //             }))
    //         })
    //     } else {
    //         setLoading(false)
    //         setUploadedFiles(files)
    //     }
    // };


    const handleRemove = async (file) => {
        let existing_data = await incidentFileID.filter(item => item !== file.file_id)
        setIncidentFileId(existing_data);
        return false
    };

    console.log({ loading })

    const handleChange = (newFileList, fileType) => {
        let keys = []
        const newFiles = newFileList.filter(file => {
            return !file?.isprocessed
        })
        if (newFiles.length == 0) {
            setLoading(false)
            return
        }

        newFiles.forEach(file => {

            keys.push(file.fileKey)
            setLoading(true)

            // fileChunksUploder({
            //     file: file.blobFile,
            //     uploader: uploadFileChunked,
            //     track_id: randomTrackID,
            //     emirates_id: false,
            //     fileKey: file?.fileKey,
            //     crmDestinationpath: 'request',
            // }, (status, res) => {
            //     console.log({ filename: file.name })
            //     setLoading(false)
            //     if (status) {
            //         file.isemirated = false;
            //         file.isprocessed = true;
            //         file.file_id = res.data.data.fileIds[0]
            //         setIncidentFileId(s => [...s, ...res.data.data.fileIds]);
            //     }
            // })

            const formData = new FormData();
            formData.append('track_id', randomTrackID);
            formData.append('emirates_id', false);
            formData.append('fileKey', file?.fileKey);
            formData.append('crmDestinationpath', 'request')
            formData.append('file', file.blobFile);

            fileUploader(formData, (res) => {
                setLoading(false)
                file.isemirated = false;
                file.isprocessed = true;
                file.file_id = res.data.fileIds[0]
                setIncidentFileId(s => [...s, ...res.data.fileIds]);
            })
        })
    }

    const preventMinus = (e) => {
        if (e.code === 'Minus') {
            e.preventDefault();
        }
    };

    function submitHandler() {

        let errs = {}
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phoneRegex = /^\d{6,13}$/;
        if (!inputs?.incident_type?.incident_type_en?.trim()) { errs.incident_type = t('Required Field') }
        // if (!inputs.incident_location?.trim()) { errs.incident_location = t('Required Field') }
        // if (!inputs.incident_date) { errs.incident_date = t('Required Field') }
        if (!inputs.agreement) { errs.agreement = t('Please agree the terms and conditions') }
        // if (!inputs.customer_email?.trim()) {
        //     errs.customer_email = t('Required Field');
        // } else
        if (inputs.customer_email?.trim() && !emailRegex.test(inputs.customer_email)) {
            errs.customer_email = t('Invalid email address');
        }

        // if (!inputs.customer_contact?.trim()) {
        //     errs.customer_contact = t('Required Field');
        // } else
        if (inputs.customer_contact?.trim() && !phoneRegex.test(inputs.customer_contact)) {
            errs.customer_contact = t('Invalid Phone number');
        }

        // if (!inputs.descripition?.trim()) { errs.descripition = t('Required Field') }
        if (!recaptchaVerified) { errs.captcha = t('Please check Captcha') }

        setErrors(errs)
        if (Object.keys(errs).length > 0) return
        setSubmitLoading(true)
        reportIncident({
            incident_type: inputs?.incident_type?._id,
            incident_location: inputs.incident_location,
            incident_date: inputs.incident_date,
            incident_latitude: incidentLatLng?.lat,
            incident_longitude: incidentLatLng?.lng,
            // customer_email: inputs.customer_email,
            // customer_contact: !flagImage?.code ? '+971' + inputs.customer_contact : flagImage?.code + inputs.customer_contact,
            customer_email: user?.email ? user?.email : inputs.customer_email,
            customer_contact: inputs.customer_contact,
            customer_contact_code: !flagImage?.code ? '+971' : flagImage?.code,
            descripition: inputs.descripition,
            latitude: currentLatLng?.lat,
            longitude: currentLatLng?.lng,
            login_user_email: user?.email,
            is_login: userId ? true : false,
            request_type: 'website',
            track_id: randomTrackID,
            social_id: userId,
            ip_addr: ipAddress,
            device_id: device_id,
            device: 'Website',
            precise_location: placeName,
            fileIds: incidentFileID,
            crmDestinationpath: 'request'
        }, (res) => {
            if (res.status) {
                setSubmitLoading(false)
                setRefeId(res?.ticket_no)
                setInputs({})
                setSuccess(true)

                // setTimeout(() => {
                //     setSuccess(false)
                //     setShow(false)
                // }, 5000);
            } else {
                setSubmitLoading(false)
                setShow(false)
            }
        })
    }


    const handleCountry = (e) => {
        const code = e?.code
        const selected = countries.find(item => item.code === code);
        setFlagImage(selected)
    }




    return (
        <>
            {
                success ?
                    <div className="report__success">
                        <div className="icon"><img src="assets/img/icons/success.svg" alt="" /></div>
                        <h2>{t('Thank you Incident Submitted successfully')}</h2>
                        <p>{t('Your incident submission was successful')}. {t('Our team will now review and address your concerns promptly')}.</p>
                    </div>

                    :
                    <div className='row rsuite-wrapper'>
                        <div className="col-md-12">
                            <div className="form-group">
                                <Select options={incidentTypes}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    getOptionLabel={(e) => e[langSwitch ? 'incident_type_en' : 'incident_type_ar']}
                                    getOptionValue={(e) => e?._id}
                                    placeholder={t('Incident Types') + "*"}
                                    value={inputs?.incident_type || ''}
                                    onChange={(e) => changeHandler('incident_type', e)}
                                />
                                <small className='error'>{errors.incident_type}</small>
                            </div>
                        </div>
                        <div className="col-md-6 google__place">

                            <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged} >
                                <div className="form__icon">
                                    <input
                                        type="text"
                                        className='form-control'
                                        ref={inputRef}
                                        placeholder={t('Select Incident Location')}
                                    />
                                    <img src="assets/img/icons/location.svg" className='location' alt="" />
                                    <small className='error'>{errors.incident_location}</small>
                                </div>
                            </Autocomplete>
                        </div>
                        <div className="col-md-6">
                            <div className="form__icon">
                                <ReactDatePicker
                                    showTimeSelect
                                    selected={inputs.incident_date}
                                    onChange={(e) => changeHandler('incident_date', e)}
                                    placeholderText={t('Enter Date')}
                                    // maxDate={new Date()}
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                />
                                <img src="assets/img/icons/calendar.svg" alt="" />
                                <small className='error'>{errors.incident_date}</small>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form__icon">
                                <input type="text" value={inputs.customer_email} placeholder={t('Email')} className='form-control' onChange={(e) => changeHandler('customer_email', e.target.value)} />
                                <img src="assets/img/icons/email.svg" alt="" />
                                <small className='error'>{errors.customer_email}</small>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form__number">
                                <div className="flag__grid">
                                    <Select options={countries}
                                        components={{
                                            IndicatorSeparator: () => null
                                        }}
                                        className='countrySelect'
                                        getOptionLabel={(e) => e.code}
                                        getOptionValue={(e) => e?.code}
                                        defaultValue={+971}
                                        placeholder='+971'
                                        onChange={(e) => handleCountry(e)}

                                    />
                                    {
                                        flagImage?.flag ? <img src={FLAG_BASE_URL + 'flags/' + flagImage?.flag} className='flag' alt="" /> :
                                            <img src={'assets/img/icons/united_arab_emirates.svg'} className='flag' alt="" />
                                    }

                                    <input type="number" value={inputs?.customer_contact || user?.phone || ''} placeholder={t('Phone Number')} onKeyDown={preventMinus} className='form-control' onChange={(e) => changeHandler('customer_contact', e.target.value)} />
                                </div>
                                <small className='error'>{errors.customer_contact}</small>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <textarea value={inputs?.descripition || ''} className='form-control' rows={3} placeholder={t('Description')} onChange={(e) => changeHandler('descripition', e.target.value)}></textarea>
                                <small className='error'>{errors.descripition}</small>
                            </div>
                        </div>
                        <div className="col-md-12 repo-incident-upldr">
                            <Uploader
                                listType="picture-text"
                                onChange={newFileList => handleChange(newFileList)}
                                draggable
                                autoUpload={false}
                                accept='image/*, application/pdf, video/*, audio/*'
                                multiple
                                shouldQueueUpdate={file => handlbeforeUpload(file)}
                                onRemove={handleRemove}
                            >
                                <div className="fileUploader">
                                    <img src="assets/img/icons/file-upload.svg" alt="" />
                                    <p>{t('drop_file')}</p>
                                </div>
                            </Uploader>
                            <p className='error'>{errors.file_upload}</p>
                            {loading && <div className="progress mb-3"></div>}
                        </div>
                        <div id="IncidentCaptcha"></div>
                        <small className='error'>{errors.captcha}</small>
                        <div className="col-md-12 mt-3">
                            <div className="form-group checkbox">
                                <input type="checkbox" id="agreement" checked={inputs?.agreement || false} onChange={(e) => changeHandler('agreement', e.target.checked)} />
                                <a href={'/terms'} target='_blank'><label>{t('I agree to the terms and conditions')}</label></a>
                            </div>
                            <small className='error'>{errors.agreement}</small>
                        </div>

                        <div className="text-center mt-2">
                            <button type='submit' disabled={submitLoading || loading} className='btn btn-primary' style={{ padding: '16px 30px' }} onClick={submitHandler}>{t('Report Incident')}
                                {submitLoading ? <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner> : <img src="assets/img/icons/arrow-right-red.svg" alt="" />} </button>
                        </div>
                    </div>
            }

        </>

    )
}

export default ReportIncident